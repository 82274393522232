import './App.css';
import Login from './components/Login.js';

const App = () => {
  return (
    <div>
      <Login />
    </div>
  );
}
export default App;
