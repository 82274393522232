import bgvideo from '../mp4/bg.jpg';

const Login = () => {
    return (
        <div className="home-splash">
            <div className="transparent-opacity">
                <img src={bgvideo} alt="City"/>
            </div>
            <div className="form-space">
                    <h1 className='login-header'>Goverrn</h1>  
                    <p className="login-description"><b>Revolutionizing Government Communication</b></p>
                    <div className="mb-3">
                        <label className="form-label"><p className='login-description'>Closed Beta 0.0.1<br /> For more information, please contact the administrator.</p></label>
                        <input type="email" className="form-control transparent-form" id="username" placeholder="Username"  style={{color: 'gray'}}/><br />
                        <input type="password" className="form-control transparent-form" id="password" placeholder="Password" /><br />
                        <button type="button" className="btn btn-outline-primary" style={{color: "white"}}>Login</button>
                    </div>

            </div>
        </div>
    )
}
export default Login